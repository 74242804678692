import React, { useState, useEffect, useContext } from "react";
import { getDataWithToken } from "../../services/GetDataService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Skeleton from "@mui/material/Skeleton";
import { Link, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PulseLoader from "react-spinners/PulseLoader";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import StoreIcon from "@mui/icons-material/Store";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Switch from "@mui/material/Switch";
import { InputAdornment } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  tableBodyStyle: {
    "& tr:nth-of-type(odd)": {
      background: "#f3f3f3",
    },
    "& tr:last-child": {
      background: "none",
    },

    "& tr:hover": {
      // cursor: "pointer",
      background: "#DCDCDC",
    },
    "& tr:last-child:hover": {
      // cursor: "default",
      background: "none",
    },
    "& td": {
      padding: "10px 6px",
    },
    [theme.breakpoints.down("xl")]: {
      "& td": {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        padding: "12px 6px",
      },
      // "& td:nth-child(n+3)": {
      //   paddingLeft: "0px",
      // },
    },
  },
  tableHeadStyle: {
    background: "#00848A",
    "& th": {
      color: "#fff",
      fontSize: "16px",
    },
    // [theme.breakpoints.down("xl")]: {
    //   "& th:nth-child(n+2)": {
    //     paddingLeft: "10px",
    //   },
    // },
  },
  titleBox: {
    background: "#eaecf3",
    fontSize: "20px",
    fontWeight: 600,
    textAlign: "center",
    padding: "5px",
    borderRadius: "8px",
    width: "50%",
    margin: "0px auto 20px",
  },
  imageStyle: {
    height: "90px",
    width: "90px",
    borderRadius: "8px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    cursor: "pointer",
  },
}));

const MerchantList = ({ rollPermission }) => {
  const classes = useStyles();
  const uploadImage = "/image/noimage2.jpg";
  const { fastpay_support_panel_user } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [merchantList, setMerchantList] = useState([]);
  const [open, setOpen] = useState(true);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addStoreLoading, setAddStoreLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openAddStoreDialog, setOpenAddStoreDialog] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [storeLogoImageFile, setstoreLogoImageFile] = useState(null);
  const [storeLogoPreview, setstoreLogoPreview] = useState(uploadImage);
  const [businessCategoryId, setBusinessCategoryId] = useState("");
  const [businessCategorylist, setBusinessCategorylist] = useState([]);
  const [businessCategoryeMessage, setBusinessCategoryeMessage] = useState([]);
  const [posDeviceId, setPosDeviceId] = useState("");
  const [filterPosDeviceId, setFilterPosDeviceId] = useState("");
  const [address, setAddress] = useState("");
  const [userId, setUserId] = useState("");
  const [levelId, setLevelId] = useState("");
  const [levelMessage, setLevelMessage] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [iban, setIban] = useState("");
  const [newUpdateValue, setNewUpdateValue] = useState({});
  const [selectedForUpdate, setSelectedForUpdate] = useState({});
  const [switchOpen, setSwitchOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [terminalName, setTerminalName] = useState("");
  const [terminalAccountNo, setTerminalAccountNo] = useState("");
  const [filterIbanNo, setFilterIbanNo] = useState("");
  const [filterBusinessMobileNo, setFilterBusinessMobileNo] = useState("");
  const updateStatus = async (e) => {
    e.preventDefault();

    try {
      setUpdateLoading(true);
      let newStatus;
      if (newUpdateValue.value) {
        newStatus = 3;
      } else {
        newStatus = 6;
      }

      let data = {
        user_id: newUpdateValue.id,
        status: newStatus,
      };

      let response = await axios({
        url: `api/pos-merchant-status-update`,
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${fastpay_support_panel_user.token}`,
        },
      });

      if (response.data.code === 200) {
        getData();
        setSwitchOpen(false);
      }

      handleSnakbarOpen(response.data.messages.toString(), "success");
      setUpdateLoading(false);
    } catch (error) {
      console.log("error", error);

      handleSnakbarOpen(error.response.data.messages.toString(), "error");
      setUpdateLoading(false);
    }
    setUpdateLoading(false);
  };
  const handleSwitchClose = () => {
    setSwitchOpen(false);
  };

  const handleClickOpen = (id) => {
    setUserId(id);
    setOpenAddStoreDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenAddStoreDialog(false);
      clearForm();
    }
  };

  const handleSnakbarOpen = (msg, vrnt) => {
    let duration;
    if (vrnt === "error") {
      duration = 3000;
    } else {
      duration = 1000;
    }
    enqueueSnackbar(msg, {
      variant: vrnt,
      autoHideDuration: duration,
    });
  };

  const pageLoading = () => {
    let content = [];

    for (let i = 0; i < 10; i++) {
      content.push(
        <TableRow key={i}>
          <TableCell colSpan={2}>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
          <TableCell>
            <Skeleton></Skeleton>
          </TableCell>
        </TableRow>
      );
    }
    return content;
  };

  const clearFilter = (event) => {
    setFilterIbanNo("");
    setFilterPosDeviceId("");
    setStatus("");
    setMobileNumber("");
    setName("");
    setFilterBusinessMobileNo("");
    setPage(0);
    let newUrl = `api/pos-merchant-list?page=1`;
    getData("", newUrl);
  };

  const handleChangePage = (event, newPage) => {
    let pageNo = newPage + 1;
    getData(pageNo);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, rowsPerPage));
    setPage(0);
  };

  const getData = async (pageNO, newUrl) => {
    try {
      setLoading(true);
      let newPageNO;
      let url;
      if (pageNO) {
        newPageNO = pageNO;
      } else {
        newPageNO = 1;
      }
      if (newUrl) {
        url = newUrl;
      } else {
        let newStatus = status;

        if (status === "None") {
          newStatus = "";
        }
        let newStoreId = "";
        let newMobileNo = "";

        if (mobileNumber.trim().length > 0) {
          newStoreId = `+964${mobileNumber.trim()}`;
        }

        if (filterBusinessMobileNo.trim().length > 0) {
          newMobileNo = `+964${filterBusinessMobileNo.trim()}`;
        }
        url = `api/pos-merchant-list?business_name=${name.trim()}&store_id=${encodeURIComponent(
          newStoreId
        )}&mobile_no=${encodeURIComponent(
          newMobileNo
        )}&terminal_iban_number=${filterIbanNo.trim()}&pos_device_id=${filterPosDeviceId}&status=${newStatus}&page=${newPageNO}`;
      }
      let merchants = await getDataWithToken(url);
      console.log("merchants", merchants);
      if (merchants.status === 200) {
        setMerchantList(merchants.data.data.data);
        setTotalData(merchants.data.data.total);
        setRowsPerPage(merchants.data.data.per_page);
        if (merchants.data.data.data.length < 1) {
          setMessage("No data found");
        }
      } else {
        setMessage(merchants.data.messages.toString());
        if (merchants.data.messages.length < 1) {
          setMessage("Something went wrong");
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setMessage(error.response.data.message.toString());
    }
    setLoading(false);
  };

  const validation = () => {
    let isError = false;
    // if (storeLogoImageFile === null) {
    //   handleSnakbarOpen("Please upload your logo", "error");
    //   return (isError = true);
    // }
    if (!storeName.trim()) {
      handleSnakbarOpen("Please enter terminal name", "error");
      document.getElementById("storeName").focus();
      return (isError = true);
    }
    if (!businessCategoryId) {
      handleSnakbarOpen("Please select a business category", "error");
      document.getElementById("businessCategoryId").focus();
      return (isError = true);
    }
    if (!posDeviceId.trim()) {
      handleSnakbarOpen("Please enter POS device id", "error");
      document.getElementById("posDeviceId").focus();
      return (isError = true);
    }
    if (!terminalAccountNo.trim()) {
      handleSnakbarOpen("Please enter terminal account no", "error");
      document.getElementById("terminalAccountNo").focus();
      return (isError = true);
    }

    if (!clientId) {
      handleSnakbarOpen("Please enter  client id", "error");
      document.getElementById("clientId").focus();
      return (isError = true);
    }
    if (!clientSecret) {
      handleSnakbarOpen("Please enter  client secret", "error");
      document.getElementById("clientSecret").focus();
      return (isError = true);
    }
    if (!iban) {
      handleSnakbarOpen("Please enter iban", "error");
      document.getElementById("iban").focus();
      return (isError = true);
    }
    if (!address.trim()) {
      handleSnakbarOpen("Please enter address", "error");
      document.getElementById("address").focus();
      return (isError = true);
    }

    return isError;
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    let err = validation();

    if (err) {
      return;
    } else {
      setAddStoreLoading(true);
      try {
        let formData = new FormData();
        formData.append("business_id", userId);
        formData.append("store_name", storeName);
        formData.append("business_category_id", businessCategoryId);
        formData.append("fib_client_id", clientId);
        formData.append("fib_client_secret", clientSecret);
        formData.append("iban", iban);
        formData.append("pos_device_id", posDeviceId);
        // formData.append("terminal_name", terminalName);
        formData.append("store_id", `+964${terminalAccountNo}`);
        formData.append("address", address);
        if (storeLogoImageFile !== null) {
          formData.append("store_logo", storeLogoImageFile);
        }
        let response = await axios({
          url: "api/pos-store-create",
          method: "post",
          data: formData,
          headers: {
            Authorization: `Bearer ${fastpay_support_panel_user.token}`,
          },
        });
        if (response.data.code === 200) {
          handleSnakbarOpen(response.data.messages.toString(), "success");
          handleClose();
          clearForm();
          getData();
        }
      } catch (error) {
        console.log("error", error);
        setAddStoreLoading(false);
        handleSnakbarOpen(error.response.data.messages.toString(), "error");
        if (error.response.data.messages.length < 1) {
          handleSnakbarOpen("Something went wrong", "error");
        }
      }
      setAddStoreLoading(false);
    }
  };
  const imageProcess = (e, title) => {
    console.log("imageProcess title", title);
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (x) => {
        setstoreLogoImageFile(imageFile);
        setstoreLogoPreview(x.target.result);
      };
      reader.readAsDataURL(imageFile);
    } else {
      setstoreLogoImageFile(null);
    }
  };

  const clearForm = () => {
    setUserId("");
    setStoreName("");
    setBusinessCategoryId("");
    setPosDeviceId("");
    setClientId("");
    setClientSecret("");
    setAddress("");
    setTerminalName("");
    setTerminalAccountNo("");
    setIban("");
    setstoreLogoImageFile(null);
  };
  const getBusinessCategoryList = async () => {
    let businessCategory = await getDataWithToken(`api/business/categories`);

    if (businessCategory.status === 200) {
      setBusinessCategorylist(businessCategory.data.data);
    } else {
      setBusinessCategoryeMessage(businessCategory.data.messages.toString());
      if (businessCategory.data.messages.length < 1) {
        setBusinessCategoryeMessage("Something went wrong");
      }
    }
  };

  useEffect(async () => {
    getBusinessCategoryList();
    getData();
  }, []);
  return (
    <>
      <TableContainer
        component={Paper}
        style={{ padding: "20px 16px 16px", boxSizing: "border-box" }}
      >
        <Grid container columnSpacing={3} style={{ padding: "16px 0" }}>
          <Grid item lg={6} xl={6}>
            <Typography variant="h4" color="info" gutterBottom component="div">
              Merchant List
            </Typography>
          </Grid>
          <Grid item lg={6} xl={6} style={{ textAlign: "right" }}>
            <Button
              disableElevation
              variant="outlined"
              size="large"
              color="success"
              // startIcon={<FilterListIcon />}
              onClick={() => setOpen(!open)}
            >
              {open ? <FilterListOffIcon /> : <FilterListIcon />}
            </Button>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    id="name"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Business Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="name"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Business Account No"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">(+964)</InputAdornment>
                      ),
                    }}
                    value={filterBusinessMobileNo}
                    onChange={(e) => setFilterBusinessMobileNo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="mobile-no"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Terminal Account Number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">(+964)</InputAdornment>
                      ),
                    }}
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="mobile-no"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Terminal IBAN Number"
                    value={filterIbanNo}
                    onChange={(e) => setFilterIbanNo(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="email"
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="POS Device Id"
                    value={filterPosDeviceId}
                    onChange={(e) => setFilterPosDeviceId(e.target.value)}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <InputLabel id="demo-is-assigned-outlined-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-is-assigned-outlined-label"
                      id="demo-is-assigned-outlined"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      label="Status"
                    >
                      <MenuItem value="None">None</MenuItem>
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <Grid container spacing={{ lg: 6, xl: 3 }}>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        disableElevation
                        size="large"
                        fullWidth
                        onClick={(event) => clearFilter(event, 0)}
                      >
                        <RestartAltIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        variant="contained"
                        disableElevation
                        // color="success"
                        style={{ background: "#435185", padding: "6.7px 22px" }}
                        size="large"
                        startIcon={<SearchIcon />}
                        fullWidth
                        onClick={(event) => handleChangePage(event, 0)}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>

        <Paper>
          <div
            style={{
              overflowX: "auto",
              minWidth: "100%",
              width: "Calc(100vw - 370px)",
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className={classes.tableHeadStyle}>
                <TableRow>
                  <TableCell colSpan={2} style={{ minWidth: "230px" }}>
                    Basic Info
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>
                    Business Name
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: "200px" }}>
                    Business Account No
                  </TableCell>

                  <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                    Is Merchant Active
                  </TableCell>
                  {/* <TableCell align="center" style={{ minWidth: "120px" }}>
                    {" "}
                    Status
                  </TableCell> */}
                  <TableCell align="center" style={{ minWidth: "380px" }}>
                    {" "}
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBodyStyle}>
                {!loading &&
                  merchantList.length > 0 &&
                  merchantList.map((row, i) => (
                    <TableRow
                      key={i}
                      // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell style={{ paddingRight: 0, width: "50px" }}>
                        <Avatar
                          alt=""
                          src={row.avatar}
                          sx={{ width: 45, height: 45 }}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          gutterBottom
                          component="div"
                          style={{ fontSize: "16px", fontWeight: 500 }}
                        >
                          {row.name}
                        </Typography>
                        {row.email}
                      </TableCell>
                      <TableCell align="center">{row.business_name}</TableCell>
                      <TableCell align="center">{row.mobile}</TableCell>

                      {/* <TableCell align="center">
                        <img
                          src={
                            row.is_kyc_verified === 1
                              ? "/image/verified.svg"
                              : "/image/unverified.svg"
                          }
                          alt=""
                          height="20px"
                          style={{ position: "relative", top: 5 }}
                        />{" "}
                        &nbsp;
                        {row.is_kyc_verified === 1 ? (
                          <span style={{ color: "rgba(129,199,132,1)" }}>
                            Verified
                          </span>
                        ) : (
                          <span style={{ color: "rgba(211, 47, 47,1)" }}>
                            Unverified
                          </span>
                        )}
                      </TableCell> */}
                      <TableCell align="center">
                        {/* <img
                          src={
                            row.is_user_active === 1
                              ? "/image/verified.svg"
                              : "/image/unverified.svg"
                          }
                          alt=""
                          height="20px"
                          style={{ position: "relative", top: 5 }}
                        />{" "}
                        &nbsp;
                        {row.is_user_active === 1 ? (
                          <span style={{ color: "rgba(129,199,132,1)" }}>
                            Active
                          </span>
                        ) : (
                          <span style={{ color: "rgba(211, 47, 47,1)" }}>
                            Inactive
                          </span>
                        )} */}
                        <Switch
                          color="success"
                          checked={row.is_user_active === 1 ? true : false}
                          onChange={(e) => {
                            setNewUpdateValue({
                              index: i,
                              id: row.id,
                              value: e.target.checked,
                            });
                            setSelectedForUpdate(row);

                            setSwitchOpen(true);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </TableCell>
                      {/*                      
                      <TableCell
                        align="center"
                        style={{
                          minWidth: "120px",
                          // color: "#fff",
                          color:
                            row.status === "Escalated"
                              ? "rgba(77,208,225,1)"
                              : row.status === "Solved"
                              ? "#1b5e20"
                              : row.status === "On Hold"
                              ? "#696e70"
                              : row.status === "In Progress"
                              ? "#4caf50"
                              : "#1976d2",
                        }}
                      >
                        {row.status}
                      </TableCell> */}
                      <TableCell align="center">
                        <Button
                          disableElevation
                          variant="outlined"
                          size="small"
                          color="info"
                          startIcon={<AddIcon />}
                          onClick={() => handleClickOpen(row?.id)}
                        >
                          Add POS
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          variant="outlined"
                          size="small"
                          color="warning"
                          startIcon={<StoreIcon />}
                          // onClick={() => getStoreList(row?.id)}
                          component={Link}
                          to={`/pos-list/${row.id}`}
                        >
                          POS List
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          variant="outlined"
                          size="small"
                          // color="warning"
                          startIcon={<EditOutlinedIcon />}
                          component={Link}
                          to={`/update-merchant/${row.id}`}
                        >
                          Update Merchant
                        </Button>
                      </TableCell>
                      {/* <TableCell align="center">
                        <IconButton onClick={() => handleClickOpen(row)}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  ))}

                {!loading && merchantList.length < 1 ? (
                  <TableRow>
                    <TableCell colSpan={7} style={{ textAlign: "center" }}>
                      <strong> {message}</strong>
                    </TableCell>
                  </TableRow>
                ) : null}
                {loading && pageLoading()}
              </TableBody>
            </Table>
          </div>
          {merchantList.length > 0 && (
            <div>
              <TablePagination
                style={{ display: "block", borderBottom: "none" }}
                rowsPerPageOptions={[]}
                // count={rows.length}
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          )}
        </Paper>
      </TableContainer>
      <Dialog
        open={openAddStoreDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent style={{ position: "relative" }}>
          <IconButton
            onClick={handleClose}
            style={{ position: "absolute", right: 20, top: 10 }}
          >
            <ClearIcon />
          </IconButton>
          <div
            style={{
              margin: "30px auto 20px",
            }}
            className={classes.titleBox}
          >
            Add POS
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={
                    storeLogoImageFile === null ? uploadImage : storeLogoPreview
                  }
                  alt=""
                  className={classes.imageStyle}
                  onClick={() =>
                    document.getElementById("upload-logo-file").click()
                  }
                />
                <Button
                  size="small"
                  variant="outlined"
                  style={{
                    marginTop: "5px",
                  }}
                  onClick={() =>
                    document.getElementById("upload-logo-file").click()
                  }
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  Upload Logo
                </Button>
              </div>

              <input
                accept="image/png, image/jpg, image/jpeg"
                className={classes.input}
                id="upload-logo-file"
                type="file"
                onChange={(e) => imageProcess(e, "logo")}
                style={{ display: "none" }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="storeName"
                label="Terminal Name"
                fullWidth
                size="small"
                variant="outlined"
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="businessCategoryId">
                  Business Category
                </InputLabel>
                <Select
                  labelId="demo-status-outlined-label"
                  id="businessCategoryId"
                  label="Business Category"
                  value={businessCategoryId}
                  onChange={(e) => setBusinessCategoryId(e.target.value)}
                >
                  <MenuItem value="None">
                    {businessCategoryeMessage.length > 0 ? (
                      <>{businessCategoryeMessage}</>
                    ) : (
                      "None"
                    )}
                  </MenuItem>
                  {businessCategorylist?.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="posDeviceId"
                label="POS Device Id"
                fullWidth
                size="small"
                variant="outlined"
                value={posDeviceId}
                onChange={(e) => setPosDeviceId(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                id="terminalName"
                label="Terminal Name"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                value={terminalName}
                onChange={(e) => setTerminalName(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                id="terminalAccountNo"
                label="Terminal Account No"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">(+964)</InputAdornment>
                  ),
                }}
                value={terminalAccountNo}
                onChange={(e) => setTerminalAccountNo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="clientId"
                label="Client Id"
                fullWidth
                size="small"
                variant="outlined"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="clientSecret"
                label="Client Secret"
                fullWidth
                size="small"
                variant="outlined"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="iban"
                label="IBAN"
                fullWidth
                size="small"
                variant="outlined"
                value={iban}
                onChange={(e) => setIban(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                label="Address"
                fullWidth
                size="small"
                className={classes.inputStyle}
                variant="outlined"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                color="primary"
                style={{ marginTop: "20px" }}
                disabled={addStoreLoading}
                onClick={onSubmit}
              >
                {addStoreLoading === false && "Add Store"}
                <PulseLoader
                  color={"#00848A"}
                  loading={addStoreLoading}
                  size={10}
                  speedMultiplier={0.5}
                />{" "}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
      <Dialog
        open={switchOpen}
        onClose={handleSwitchClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "10px" }}>
          <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You want to change status
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSwitchClose}>cancel</Button>
            <Button
              variant="contained"
              disabled={updateLoading}
              onClick={updateStatus}
              style={{ minWidth: "100px" }}
              autoFocus
            >
              <PulseLoader
                color={"#00848A"}
                loading={updateLoading}
                size={10}
                speedMultiplier={0.5}
              />{" "}
              {updateLoading === false && "Confirm"}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default MerchantList;
